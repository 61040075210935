import { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useQuery, useReactiveVar } from '@apollo/client';
import * as yup from 'yup';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { PORTFOLIO_OPTIONS } from '@fingo/lib/graphql';
import { openUrlAndDownload, formatYearMonthDay } from '@fingo/lib/helpers';
import moment from 'moment';
import usePortfolioDownload from '@fingo/lib/hooks/usePortfolioDownload';
import currencyVar from '@fingo/lib/apollo/reactive-variables/money';

const PORTFOLIO_FORM_INITIAL_VALUES = {
  portfolioType: '',
  documentFormat: '',
  startDate: moment(),
  endDate: moment(),
  currency: '',
};

const validationSchema = yup.object({
  documentFormat: yup.string().required('Formato es requerido'),
});

const usePortfolioForm = (product) => {
  const { data, loading } = useQuery(PORTFOLIO_OPTIONS);

  const selectedCompany = useSelectedCompany();

  const { loading: loadingPortfolio, generatePortfolioUrl } = usePortfolioDownload();

  const portfolioFormats = useMemo(() => data?.getPortfolioFormatEnum, [data]);

  const currency = useReactiveVar(currencyVar);

  // TODO: Javier Rosas has to fix surplus portfolio
  const portfolioTypes = [
    {
      value: 'DEFAULT',
      label: 'Cartera morosa vigente',
      __typename: 'PortfolioSummaryEnumType',
    },
    {
      value: 'CURRENT',
      label: 'Cartera vigente',
      __typename: 'PortfolioSummaryEnumType',
    },
    {
      value: 'HISTORIC',
      label: 'Cartera histórica',
      __typename: 'PortfolioSummaryEnumType',
    },
  ];

  const formik = useFormik({
    initialValues: PORTFOLIO_FORM_INITIAL_VALUES,
    validationSchema,
    onSubmit: (values) => {
      generatePortfolioUrl({
        variables: {
          product,
          companyId: selectedCompany?.id,
          portfolioType: values.portfolioType || null,
          documentFormat: values.documentFormat,
          startDate: values.startDate
            ? formatYearMonthDay(values.startDate)
            : null,
          endDate: values.endDate ? formatYearMonthDay(values.endDate) : null,
          currency,
        },
        onCompleted: ({ generatePortfolioSummary: { url } }) => {
          openUrlAndDownload(url, 'portfolio');
        },
      });
    },
  });

  const handleRangeChange = useCallback(
    ({ range }) => {
      formik.setFieldValue('startDate', moment(range.startDate));
      formik.setFieldValue('endDate', moment(range.endDate));
    },
    [formik],
  );

  return {
    formik,
    portfolioFormats,
    portfolioTypes,
    loading,
    loadingPortfolio,
    handleRangeChange,
  };
};

export default usePortfolioForm;
