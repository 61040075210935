import { gql } from '@apollo/client';

export const ACEPTA_LOGIN_SESSION_ID = gql`
  mutation aceptaAuthSessionId(
    $sessionId: String!
    $buttonFlag: String
    $clientRut: String
    $companyRut: String
  ) {
    aceptaAuthSessionId(
      sessionId: $sessionId
      buttonFlag: $buttonFlag
      clientRut: $clientRut
      companyRut: $companyRut
    ) {
      token
      refreshToken
      actualCompanyRut
    }
  }
`;

export const ACEPTA_LOGIN = gql`
  mutation AceptaAuth($rut: String!, $password: String!) {
    aceptaAuth(rut: $rut, password: $password) {
      token
      refreshToken
    }
  }
`;
