import { useQuery, useReactiveVar } from '@apollo/client';
import { COUNT_FACTORING } from '@fingo/lib/graphql';
import { useSelectedCompany } from '@fingo/lib/hooks';
import currencyVar from '@fingo/lib/apollo/reactive-variables/money';
import { evaluatingIssuedGte } from '../constants';

const useCountFactoring = () => {
  const selectedCompany = useSelectedCompany();
  const currency = useReactiveVar(currencyVar);
  const { data } = useQuery(COUNT_FACTORING, {
    variables: {
      currency,
      companyId: selectedCompany?.id,
      evaluatingIssuedGte,
    },
    skip: !selectedCompany,
  });
  return {
    preoffers: data?.preoffers.totalCount,
    offers: data?.offers.totalCount,
    cessions: data?.cessions.totalCount,
    evaluations: data?.evaluations.totalCount,
  };
};

export default useCountFactoring;
